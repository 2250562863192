import { ValidationObserver, ValidationProvider } from 'vee-validate'
import globalHelper from '@/mixins/global'

export default {
  name: 'user-recover',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      success: false,
      email: null,
      recovering: false
    }
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },
  computed: {

  },

  methods: {
    async recover (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.recovering) {
        return
      }

      this.success = false

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      const url = this.$router.resolve({
        name: 'UsersReset'
      })

      const body = {
        email: this.email,
        url: `${window.location.origin}${url.href}/`
      }

      this.recovering = true
      this.$store.dispatch('user_recover', body).then(
        (response) => {
          this.recovering = false
          this.success = true

          // reset form
          this.email = null
          // You should call it on the next frame
          requestAnimationFrame(() => {
            this.$refs.observer.reset()
          })

          this.$events.$emit('user:recovered', response)
        },
        (response) => {
          this.recovering = false
          this.printErrors(response, this.$refs.observer)
        }
      )
    }
  }
}
