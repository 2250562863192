import UserLogin from '@/components/UserLogin/main'
import UserRecover from '@/components/UserRecover/main'
import UserRegister from '@/components/UserRegister/main'
import globalHelper from '@/mixins/global'

export default {
  name: 'users-login',
  components: {
    UserLogin,
    UserRegister,
    UserRecover
  },
  mixins: [globalHelper],
  data () {
    return {
      currentComponent: null
    }
  },
  beforeMount () {
    const comp = this.$route.params.action || null
    this.setComponent(comp)
  },
  created () {
    this.$events.$on('user:registered', () => {
      this.swapComponent('user-login')
    })
    this.$events.$on('user:logged', () => {
      this.$router.push({ name: 'UsersSync' })
    })
  },
  beforeDestroy () {
    this.$events.$off('user:logged')
    this.$events.$off('user:registered')
  },
  watch: {
    '$route' (to, from) {
      if (to.params && to.params.action) {
        this.setComponent(to.params.action)
      }
    }
  },

  computed: {
  },
  methods: {
    swapComponent (component) {
      this.currentComponent = component
    },
    setComponent (value) {
      switch (value) {
        case 'register': this.currentComponent = 'user-register'
          break
        case 'forgot': this.currentComponent = 'user-recover'
          break
        case 'login': this.currentComponent = 'user-login'
          break
        default: this.$router.push({ name: 'NotFound' })
      }
    }
  }
}
