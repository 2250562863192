import { ValidationObserver, ValidationProvider } from 'vee-validate'
import globalHelper from '@/mixins/global'

export default {
  name: 'user-register',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      username: null,
      email: null,
      password: null,
      cPassword: null,
      registering: false,
      success: false
    }
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },
  computed: {

  },

  methods: {
    async register (event) {
      event.preventDefault()
      event.stopPropagation()

      this.success = false

      if (this.registering) {
        return
      }

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      const body = {
        username: this.username,
        email: this.email,
        password: this.password,
        locale: this.$i18n.locale
      }

      this.registering = true
      this.$store.dispatch('user_register', body).then(
        (response) => {
          this.registering = false
          this.success = true

          // reset form
          this.username = null
          this.email = null
          this.password = null
          this.cPassword = null
          // You should call it on the next frame
          requestAnimationFrame(() => {
            this.$refs.observer.reset()
          })
        },
        (response) => {
          this.registering = false
          this.printErrors(response, this.$refs.observer)
        }
      )
    },
    dispatchSuccess (event) {
      event.preventDefault()
      event.stopPropagation()

      this.success = false
      this.$events.$emit('user:registered')
    }
  }
}
