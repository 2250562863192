import { ValidationObserver, ValidationProvider } from 'vee-validate'
import globalHelper from '@/mixins/global'

export default {
  name: 'user-login',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [globalHelper],
  data () {
    return {
      username: null,
      password: null,
      rememberMe: null,
      loggingIn: false
    }
  },
  mounted () {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'setLanguage':
          this.$refs.observer && this.$refs.observer.ctx && this.$refs.observer.ctx.invalid && this.$refs.observer.validate()
          break
      }
    })
  },

  computed: {
    remember: {
      get () {
        return this.rememberMe || this.$ls.get('remember')
      },
      set (value) {
        this.rememberMe = value
      }
    }
  },

  methods: {
    async login (event) {
      event.preventDefault()
      event.stopPropagation()

      if (this.loggingIn) {
        return
      }

      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      // store on local storage
      this.$ls.set('remember', this.remember)

      const body = {
        username: this.username,
        password: this.password
      }

      this.loggingIn = true
      this.$store.dispatch('user_login', body).then(
        (response) => {
          this.loggingIn = false
          this.$events.$emit('user:logged')
        },
        (response) => {
          this.loggingIn = false
          this.printErrors(response, this.$refs.observer)
        }
      )
    }
  }
}
